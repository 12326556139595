import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core'

import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { setContext } from '@apollo/client/link/context'

Vue.use(VueApollo)

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_API_URL
})

const contextLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')

  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null
      }
    }
  } else {
    return { headers }
  }
})

export const apolloCache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  link: contextLink.concat(httpLink),
  cache: apolloCache
})

export default function () {
  return new VueApollo({
    defaultClient: apolloClient
  })
}
