<template>
  <div class="selected-item">
    <h1>
      <span class="item-id">{{item.value.toString().padStart(6, '0')}}</span> <span v-html="item.altLabel || item.label"></span>
    </h1>

    <button @click="$emit('change')">{{changeBtnLabel}}</button>
  </div>
</template>

<script>
export default {
  name: 'SelectedItem',

  props: {
    item: {
      type: Object,
      required: true
    },
    changeBtnLabel: {
      type: String,
      default: 'Change'
    }
  }
}
</script>

<style lang="less" scoped>
.selected-item {
  font-size: 15pt;
  opacity: 0.7;
  text-align: center;

  .item-id {
    background: fade(contrast(@main-color), 10%);
    color: fade(contrast(@main-color), 50%);
    padding: 5px;
    border-radius: 10px;
    font-size: 14pt;
    font-weight: normal;
    vertical-align: middle;
  }

  button {
    border: none;
    padding: 10px;
    background: fade(contrast(@main-color), 30%);
    color: fade(contrast(@main-color), 80%);
    border-radius: 100px;
    margin-top: 30px;
    cursor: pointer;
    transition: all 200ms;

    &:hover {
      background: fade(contrast(@main-color), 50%);
    }

    &:active {
      background: fade(contrast(@main-color), 20%);
    }
  }
}
</style>
