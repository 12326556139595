import store from '../store'

export function checkPermission (permission, permissions = null) {
  const queryPermission = permission

  if (permissions === null) {
    permissions = store.state.auth?.permissions ?? []
  }

  return permissions.some(permission => {
    if (permission === '*') {
      return true
    } else if (permission.endsWith('*')) {
      const permissionPrefix = permission.replace(/\*$/g, '')

      return queryPermission.startsWith(permissionPrefix)
    } else if (queryPermission.endsWith('*')) {
      const queryPermissionPrefix = queryPermission.replace(/\*$/g, '')

      return permission.startsWith(queryPermissionPrefix)
    } else {
      return permission === queryPermission
    }
  })
}
