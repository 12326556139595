<template>
  <span class="key-bind" :class="{active: this.active}">
    <span class="key">
      <span v-for="key in formattedKey" :key="key" class="key-item">
        <span>{{key}}</span>
      </span>
    </span>
    <span class="description">{{description}}</span>
  </span>
</template>

<script>
export default {
  name: 'KeyBind',

  props: {
    _key: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    }
  },

  computed: {
    formattedKey () {
      return this._key.split('+').map(i => i.toUpperCase())
    }
  },

  methods: {
    action (e) {
      const callback = () => {
        this.$emit('action', e)

        this.active = true

        setTimeout(() => (this.active = false), 500)
      }

      if (this.formattedKey[0] === 'CTRL') {
        if (e.ctrlKey && e.key.toUpperCase() === this.formattedKey[1]) {
          callback(); return
        }
      }

      if (this.formattedKey[0] === 'SHIFT') {
        if (e.shiftKey && e.key.toUpperCase() === this.formattedKey[1]) {
          callback(); return
        }
      }

      if (this.formattedKey[0] === 'ALT') {
        if (e.altKey && e.key.toUpperCase() === this.formattedKey[1]) {
          callback(); return
        }
      }

      if (!e.ctrlKey && !e.shiftKey && !e.altKey && (e.key.toUpperCase() === this.formattedKey[0])) {
        callback()
      }
    }
  },

  data () {
    return {
      active: false
    }
  },

  mounted () {
    document.body.addEventListener('keyup', this.action)
  },

  destroyed () {
    document.body.removeEventListener('keyup', this.action)
  }
}
</script>

<style lang="less" scoped>
.key-bind {
  padding: 4px;
  border-radius: 5px;
  cursor: default;
  user-select: none;

  .key {
    margin-right: 5px;

    .key-item {
      > span {
        padding: 2px 5px;
        background: fade(contrast(@main-color), 10%);
        font-size: 9pt;
        border-radius: 4px;
        color: fade(contrast(@main-color), 40%);
      }

      &::after {
        content: '+';
        margin: 0 2px;
        font-size: 9pt;
        color: fade(contrast(@main-color), 30%);
      }

      &:last-child::after {
        content: initial
      }
    }
  }

  .description {
    color: fade(contrast(@main-color), 50%);
    font-size: 10pt;
  }

  &.active {
    animation: flash 500ms;
  }

  @keyframes flash {
    50% {
      background: fade(@main-color, 50%);
    }
  }
}
</style>
