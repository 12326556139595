<template>
  <div class="wizard">
    <div class="left">
      <div v-for="page of pages" :key="page.id">
        <div class="summary-item" v-if="page.hasSummary && page.index <= currentPage.index" :class="{ active: page.selected }">
          <div class="title">{{page.title}}</div>

          <portal-target class="portal" :name="page.id + '-summary-panel'"></portal-target>
        </div>
      </div>
    </div>

    <div class="right">
      <div v-if="loading" class="loading">
        <i class="fal fa-spinner-third fa-spin"></i>
      </div>

      <div class="contents">
        <slot/>
      </div>

      <div class="bottom">
        <div class="controls">
          <div v-if="currentPage && !currentPage.first && !currentPage.node.$props.hideBack" class="back" tabindex="0" @click="back"><i class="fas fa-arrow-circle-left"></i> Back</div>

          <div class="status-bar">
            <portal-target :name="currentPageId + '-status-bar'"></portal-target>
          </div>

          <div v-if="currentPage && !currentPage.node.$props.hideNext" class="next" tabindex="0" @click="next">
            <span v-if="currentPage && !currentPage.last">
              Next <i class="fas fa-arrow-circle-right"></i>
            </span>
            <span v-else>
              Finish <i class="fas fa-check-circle"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Wizard',

  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  methods: {
    back () {
      if (this.currentPage.first) return

      this.currentPage = this.pages.find(i => i.index === (this.currentPage.index - 1))
      this.pages.forEach(i => (i.selected = false))
      this.currentPage.selected = true

      this.firstInputFocus()

      this.currentPage.node.$emit('enter')

      this.$emit('back', this.currentPage)
      this.$emit('change', this.currentPage)
    },

    next () {
      const validate = this.currentPage.node.$props.validate()

      if (validate !== true) {
        this.currentPage.node.$emit('invalid', validate)

        return
      }

      if (this.currentPage.last) {
        this.$emit('finish')

        return
      }

      this.currentPage.node.$emit('leave')

      this.currentPage = this.pages.find(i => i.index === (this.currentPage.index + 1))
      this.pages.forEach(i => (i.selected = false))
      this.currentPage.selected = true

      this.firstInputFocus()

      this.$emit('next', this.currentPage)
      this.$emit('change', this.currentPage)
    },

    goTo (pageId) {
      this.currentPage.node.$emit('leave')

      this.currentPage = this.pages.find(i => i.id === pageId)
      this.pages.forEach(i => (i.selected = false))
      this.currentPage.selected = true

      this.firstInputFocus()
    },

    restart () {
      this.currentPage = this.pages[0]

      this.firstInputFocus()

      this.$emit('restart')
    },

    firstInputFocus () {
      this.$nextTick().then(() => {
        const firstInputElement = this.currentPage.$el.querySelector('input, select')

        if (firstInputElement) {
          firstInputElement.focus()
        }
      })
    }
  },

  watch: {
    currentPage () {
      this.pages.forEach(page => {
        page.node.show = false
      })

      this.currentPage.node.show = true
    }
  },

  computed: {
    currentPageId () {
      return this.currentPage?.title.toLowerCase().split(' ').join('-').trim()
    }
  },

  data () {
    return {
      pages: [],
      currentPage: null
    }
  },

  mounted () {
    let i = 0

    this.$children.forEach(item => {
      if (item.$vnode.componentOptions.tag === 'wizard-page') {
        this.pages.push({
          $el: item.$el,
          id: item.id,
          node: item,
          index: i++,
          title: item.$props.title,
          first: false,
          last: false,
          selected: false,
          hasSummary: item.$slots['summary-panel'] !== undefined
        })
      }
    })

    this.pages[0].selected = true
    this.pages[0].first = true
    this.pages[this.pages.length - 1].last = true

    this.currentPage = this.pages[0]
  }
}
</script>

<style lang="less" scoped>
.wizard {
  @left-panel-width: 320px;

  background-color: tint(contrast(contrast(@main-color)), 6%);
  overflow: hidden;
  display: flex;

  > div {
    flex: 1;
  }

  .left {
    flex: 0 0 @left-panel-width;
    border-right: 1px solid fade(contrast(@main-color), 5%);
    padding: 25px 15px;

    .summary-item {
      opacity: 0.5;
      margin-bottom: 25px;
      padding-bottom: 15px;

      &.active {
        opacity: 1;
      }

      .title {
        font-family: Rajdhani, sans-serif;
        font-size: 10pt;
        margin-bottom: 5px;
        opacity: 0.5;
        border-bottom: 1px dotted fade(contrast(@main-color), 30);
        text-transform: uppercase;
      }

      .portal {
        padding-left: 10px;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;

    > div {
      flex: 1;
    }

    .loading {
      flex: 0;
      position: absolute;
      top: 0;
      left: @left-panel-width;
      right: 0;
      bottom: 0;
      background: fade(shade(@main-color, 20%), 95%);
      backdrop-filter: blur(3px);
      z-index: 10;

      i {
        font-size: 60pt;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation-duration: 1s;
        text-align: center;
      }
    }

    .contents {
      padding: 0;
      overflow: auto;
      position: relative;
    }

    .bottom {
      flex: 0;

      .controls {
        display: flex;
        color: contrast(@main-color);
        background: fade(contrast(contrast(@main-color)), 10%);

        > div {
          flex: 1;
          height: 45px;
          justify-content: center;
          line-height: 45px;
        }

        .status-bar {
          text-align: center;
        }

        .back,
        .next {
          flex: 0 0 120px;
          text-align: center;
          opacity: 0.5;
          cursor: pointer;
          transition: all 300ms;
          user-select: none;
          outline: none;

          i {
            font-size: 15pt;
            vertical-align: middle;
            transition: all 400ms;
            opacity: 0.4;
          }

          &:hover,
          &:focus-visible {
            opacity: 0.9;
            background: fade(contrast(@main-color), 5%);

            i {
              opacity: 1;
            }
          }

          &:active {
            opacity: 0.5;
            background: fade(contrast(contrast(@main-color)), 50%);
          }
        }

        .back:hover i {
          transform: translateX(-5px);
        }

        .next:hover i {
          transform: translateX(5px);
        }
      }
    }
  }

}
</style>
