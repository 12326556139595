<template>
  <div class="header">
      <transition name="header-bar-slide">
        <div v-if="historyCount > 0" class="back" @click="$router.back()">
          <i class="fal fa-arrow-left"></i>
        </div>
      </transition>
      <div>
        <div class="logo">
          <img src="@/assets/tireview-fast-logo-bw-h.png" alt="TireView LIVE - F.A.S.T">
        </div>
      </div>
      <div class="right">
        <span class="user-info">
          <i class="fas fa-user-circle"></i> {{user.name}} (<strong>#{{user.employeeId}}</strong>)
        </span>

        <div class="btn-group">
          <button title="Order Processing" @click="$router.push('/')" :disabled="$route.name === 'Home'">
            <i class="fal fa-scanner"></i>
          </button>

          <button title="Change Password" @click="$router.push('/change-password')" :disabled="$route.name === 'ChangePassword'">
            <i class="fal fa-key"></i>
          </button>
        </div>

        <button title="Toggle Fullscreen" class="fullscreen" @click="toggleFullscreen">
          <i v-if="fullscreen" class="fal fa-compress"></i>
          <i v-else class="fal fa-expand"></i>
        </button>

         <button class="close" title="Sign Out" @click="logout">
          <i class="fal fa-sign-out"></i>
        </button>
      </div>
    </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'HeaderBar',

  methods: {
    logout () {
      this.$apollo.mutate({
        mutation: gql`
          mutation {
            auth {
              logout
            }
          }
        `
      }).then(() => {
        this.$store.dispatch('UNSET_AUTH')

        const url = new URL(process.env.VUE_APP_AUTH_URL)

        url.searchParams.append('app', 'fast')

        location.replace(url)
      })
    },

    toggleFullscreen () {
      if (this.fullscreen) {
        document.exitFullscreen()
      } else {
        document.documentElement.requestFullscreen()
      }
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  watch: {
    $route () {
      const count = window.history.length

      if (count > this.windowHistoryCount) {
        this.historyCount++
      } else {
        this.historyCount--
      }

      this.windowHistoryCount = count
    }
  },

  data () {
    return {
      fullscreen: false,
      historyCount: 0,
      windowHistoryCount: 0
    }
  },

  mounted () {
    document.documentElement.addEventListener('fullscreenchange', () => {
      this.fullscreen = !!document.fullscreenElement
    })
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;

  > div {
    flex: 1;
    padding: 10px;

    &.back {
      flex: 0 0 60px;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15pt;
      color: contrast(@main-color);
      transform: translateX(12px);
      transition: all 200ms;
      opacity: 0.4;
      overflow: hidden;

      &:hover {
        opacity: 1;
        transform: translateX(5px);
      }

      &:active {
        opacity: 0.6;
        transform: scale(0.8);
      }
    }

    .logo img {
      height: 30px;
      opacity: 0.6;
    }

    &.right {
      text-align: right;

      .user-info {
        color: fade(contrast(@main-color), 40%);
        margin-right: 20px;
        user-select: none;
        cursor: default;

        i {
          font-size: 16pt;
          vertical-align: middle;
          margin-right: 10px;
        }
      }

      .btn-group {
        display: inline;
        margin-right: 15px;

        button {
          border-radius: 0;
          margin: 0 !important;
          width: 50px;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      button {
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 5px;
        background: fade(contrast(@main-color), 10%);
        color: contrast(contrast(@main-color));
        font-size: 15pt;
        cursor: pointer;
        transition: all 200ms;

        &:not(.close) {
          margin-right: 5px;
        }

        &:hover {
          background: fade(contrast(@main-color), 20%);
          color: contrast(@main-color);
        }

        &:active {
          opacity: 0.6;
        }

        &:disabled {
          background: fade(contrast(@main-color), 25%);
          pointer-events: none;
        }

        &.close {
          margin-left: 15px;

          &:hover {
            background-color: @danger-color;
          }
        }
      }
    }
  }
}
</style>
