<template>
  <div class="home">
    <wizard class="wizard" ref="wizard" @finish="resetWizard" :loading="wizardPageLoading">
      <wizard-page title="Order" :validate="validateOrderPage">
        <text-input label="Order Number" v-model="order.number" @keyup.enter="parseOrderNumber" :invalid="validatePages.pageOrderInvalidOrderNumber" ref="oderNumber"/>

        <template #summary-panel>
          <div>
            <span v-if="order.number.length > 0">
              #{{order.number}}
            </span>
            <span v-else>Enter the order ID</span>
          </div>
        </template>
      </wizard-page>

      <wizard-page title="Client" :validate="validateClientPage">
        <selected-item v-if="order.client" :item="order.client" @change="changeClient" class="selected-item"/>

        <item-picker v-else :data="clientPickerOptions.list" :loading="clientPickerOptions.loading" @search="searchClient" @select="clientSelected" placeholder="Client name or ID"/>

        <template #summary-panel>
          <div>
            <span v-if="order.client" v-html="order.client.altLabel"></span>
            <span v-else>Select a client</span>
          </div>
        </template>

        <template v-if="order.client" #status-bar>
          <key-bind _key="f2" description="Change item" @action="changeClient"/>
        </template>
      </wizard-page>

      <wizard-page title="Order Items" :validate="validateOrderItemsPage" @leave="generateDevicesMockup">
        <div class="table">
          <div class="header">
            <div class="vehicle-type">Vehicle Type</div>
            <div class="location">Location</div>
            <div class="quantity">Quantity</div>
            <div class="controls"></div>
          </div>
          <div class="row" v-for="item of order.items" :key="item.id">
            <div class="vehicle-type">{{item.batchSettings.name}}</div>
            <div class="location">{{item.locations.map(i => i.name).join(', ')}}</div>
            <div class="quantity">
              {{item.quantity}}
            </div>
            <div class="controls">
              <button tabindex="-1" @click="removeOrderItem(item.id)">
                <i class="fal fa-trash"></i>
              </button>
            </div>
          </div>
          <div class="row input">
            <div class="vehicle-type">
              <item-picker :data="batchSettingsPickerOptions.list" @search="searchBatchSetting" @select="batchSettingSelected" @skip="next" placeholder="Fleet Settings name or ID" ref="batchSetting"/>
            </div>
            <div class="quantity">
              <text-input placeholder="Quantity" v-model.number="orderItem.quantity" @keyup.enter="addItem" @keyup.esc="resetItemAdding" ref="quantity"/>
            </div>
          </div>
        </div>

        <modal title="Location" ref="locationsModal">
          <div class="locations-list">
            <list-box @confirm="locationsConfirm" :options="accountLocations" optionLabel="name" dataKey="id" v-model="selectedLocations" :filter="false">
              <template #option="slot">
                <div class="location-item">
                  <div class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div>
                    <div class="name">{{slot.option.name}}</div>
                  </div>
                </div>
              </template>
            </list-box>
          </div>
          <template #footer>
            <button class="btn">Cancel</button>
            <button class="btn primary" @click="locationsConfirm">Confirm</button>
          </template>
        </modal>

        <template #summary-panel>
          <div>
            <span v-if="order.items.length > 0">
              <div><span class="id-tag order">{{orderItemsSummary.items}}</span> vehicle types</div>
              <div><span class="id-tag order">{{orderItemsSummary.total}}</span> devices total</div>
            </span>
            <span v-else>Add items to the order</span>
          </div>
        </template>

        <template v-if="order.items.length > 0" #status-bar>
          <key-bind _key="ctrl+delete" description="Remove last item" @action="removeLastOrderItem"/>
          <span class="separator">•</span>
          <key-bind _key="ctrl+arrowright" description="Finish" @action="next"/>
        </template>
      </wizard-page>

      <wizard-page title="Devices" class="devices-page" :validate="validateDevicesPage" ref="devicesPage" hideBack>
        <div v-if="devicesMockupCurrentItem" class="devices-page-content">
          <div class="header">
            <div class="total-counter">
              {{devicesMockupCurrentItem.value.totalCount}}<span>/{{devicesMockupCurrentItem.value.total}}</span>
            </div>
            <div class="title">
              <div class="counter">
                {{devicesMockupCurrentItem.value.totalOfTypeCount}}<span>/{{devicesMockupCurrentItem.value.totalOfType}}</span>
              </div>
              <div class="description">
                {{devicesMockupCurrentItem.value.batchSettings.name}} <span>({{devicesMockupCurrentItem.value.vehicle.name}})</span>

                <div class="locations">
                  <i class="fas fa-map-marker-alt"></i> {{devicesMockupCurrentItem.value.locations.map(i => i.name).join(', ')}}
                </div>
              </div>
            </div>
          </div>

          <div class="inputs-container">
            <text-input class="imei-input" type="text" maxlength="15" placeholder="IMEI" :invalid="validatePages.pageDevicesInvalidImei" @keyup.enter="goToSensors" @keyup.esc="resetImei" @focus="scrollToTop" ref="imei" v-model="devicesMockupCurrentItem.value.id"/>

            <div class="sensors-container">
              <div class="sensor-item" v-for="(sensor, index) in devicesMockupCurrentItem.value.sensors" :key="sensor.index">
                <div class="sensor-name">
                  {{sensor.name}}
                </div>
                <div>
                  <input class="sensor-input" type="text" @keyup.enter="processSensorId(sensor); goToSensor(index + 1)" @keyup.esc="resetSensor(index, sensor)" @focus="scrollToSensor(index)" ref="sensor" v-model="sensor.id">
                </div>
                <div class="meta-info">{{ sensor.meta ? sensor.meta.manufacturingDateCode : '' }}</div>
                <div class="meta-info">{{ sensor.meta ? sensor.meta.manufacturingPartNumber : '' }}</div>
                <div class="meta-info">{{ sensor.meta ? sensor.meta.manufacturingQualityControlCode : '' }}</div>
              </div>
            </div>
          </div>
        </div>

        <template #summary-panel>
          <div>
            <div class="progress">
              <div class="title">Current vehicle progress</div>
              <div class="progress-bar">
                <div :style="{width: deviceItemsProgress.type}"></div>
              </div>
            </div>
            <div class="progress">
              <div class="title">Overall progress</div>
              <div class="progress-bar">
                <div :style="{width: deviceItemsProgress.total}"></div>
              </div>
            </div>
          </div>
        </template>
      </wizard-page>

      <wizard-page title="Done" class="done-page" hideBack>
        <div v-if="orderError" class="order-error">
          <h1><i class="fas fa-minus-circle"></i> Order #{{order.number}} couldn't be processed.</h1>
          <h2>
            This order couldn't be processed by the Platform due to a technical issue.<br>
            The order has been saved, and an Administrator can retry to send it to the Platform later.
          </h2>
          <h2>The order processing ID is <strong><copiable-text>{{parsedOrderError.orderId}}</copiable-text></strong></h2>
        </div>
        <div v-else>
          <h1><i class="fal fa-check"></i> Order #{{order.number}} has been successfully processed.</h1>
          <h2>The order processing ID is <strong><copiable-text>{{order.id}}</copiable-text></strong></h2>
        </div>

        <template #status-bar>
          <key-bind _key="enter" description="Confirm" @action="resetWizard"/>
        </template>
      </wizard-page>

      <toast ref="toast"/>
    </wizard>
  </div>
</template>

<script>
import CopiableText from '@/components/CopiableText.vue'
import ItemPicker from '@/components/ItemPicker.vue'
import KeyBind from '@/components/KeyBind.vue'
import ListBox from '@/components/ListBox.vue'
import Modal from '@/components/Modal.vue'
import SelectedItem from '@/components/SelectedItem.vue'
import TextInput from '@/components/TextInput.vue'
import Toast from '@/components/Toast.vue'
import Wizard from '@/components/Wizard.vue'
import WizardPage from '@/components/WizardPage.vue'
import gql from 'graphql-tag'

const AUDIO_ERROR = new Audio(require('@/assets/error.mp3'))
const AUDIO_NOTIFY = new Audio(require('@/assets/notify.mp3'))
const AUDIO_FINISHED = new Audio(require('@/assets/finished.mp3'))

export default {
  name: 'Home',

  components: {
    Wizard,
    WizardPage,
    Toast,
    TextInput,
    ItemPicker,
    SelectedItem,
    KeyBind,
    CopiableText,
    Modal,
    ListBox
  },

  methods: {
    parseOrderNumber () {
      if (this.order.number.toString().startsWith('>>>')) {
        this.wizardPageLoading = true

        const params = this.decodeQrCode(this.order.number)

        this.order.number = params.orderNumber.toString()

        this.getAccount(params.systemId, params.accountId).then(response => {
          const account = response.data.platform.account

          this.order.client = {
            id: account.system.id + '_' + account.id,
            value: account.id,
            systemId: account.system.id,
            systemName: account.system.name,
            label: account.name,
            altLabel: `${account.name} <span class="system-tag ${account.system.id}">${account.system.name}</span> <span class="id-tag float-right">#${account.id}</span>`
          }
          this.accountBatchSettings = account.batchSettings
          this.accountLocations = account.locations
          this.order.items = []

          for (const item of params.items) {
            const batchSetting = this.accountBatchSettings.find(i => i.id === item.id)
            const locations = item.locations.length > 0
              ? item.locations.map(i => this.accountLocations.find(j => j.id === i) ?? { id: 0, name: '(None)' })
              : [{
                id: 0,
                name: '(None)'
              }]

            this.order.items.push({
              id: Math.random(),
              batchSettings: {
                id: batchSetting.id,
                name: batchSetting.description
              },
              locations,
              vehicle: {
                id: batchSetting.layout.id,
                name: batchSetting.layout.description
              },
              quantity: item.quantity
            })
          }

          this.generateDevicesMockup()

          this.$refs.wizard.goTo('devices')

          this.wizardPageLoading = false
        })
      } else {
        this.next()
      }
    },

    decodeQrCode (code) {
      const params = code.substr(3).split('|')

      return {
        orderNumber: Number(params[0]),
        systemId: params[1],
        accountId: Number(params[2]),
        items: params[3].split('#').map(item => {
          const parts = item.split(',')

          return {
            id: Number(parts[0]),
            quantity: Number(parts[1]),
            locations: parts.slice(2).map(i => Number(i))
          }
        })
      }
    },

    // ---

    searchClient (searchText) {
      this.clientPickerOptions.loading = true
      this.clientPickerOptions.list = []

      this.$apollo.query({
        query: gql`
          query ($searchText: String!) {
            platform {
              accounts: searchAccount (searchText: $searchText) {
                id
                name
                system {
                  index
                  id
                  name
                }
              }
            }
          }
        `,
        variables: {
          searchText
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.clientPickerOptions.list = response.data.platform.accounts.map(item => {
          return {
            id: item.system.id + '_' + item.id,
            value: item.id,
            systemId: item.system.id,
            systemName: item.system.name,
            label: item.name,
            altLabel: `${item.name} <span class="system-tag ${item.system.id}">${item.system.name}</span> <span class="id-tag float-right">#${item.id}</span>`
          }
        })
      }).finally(() => {
        this.clientPickerOptions.loading = false
      })
    },

    clientSelected (item) {
      this.order.client = item
      this.order.items = []

      this.wizardPageLoading = true

      this.getAccount(item.systemId, item.value).then(response => {
        this.accountBatchSettings = response.data.platform.account.batchSettings
        this.accountLocations = response.data.platform.account.locations
        this.wizardPageLoading = false

        this.next()
      })
    },

    changeClient () {
      this.order.client = null

      this.resetWizardFocus()
    },

    getAccount (systemId, accountId) {
      return this.$apollo.query({
        query: gql`
          query ($systemId: ID!, $accountId: Int!) {
            platform {
              account: getAccount (systemId: $systemId, accountId: $accountId) {
                id
                name
                system {
                  index
                  id
                  name
                }
                batchSettings {
                  id
                  description
                  layout {
                    id
                    description
                    sensors {
                      index
                      name
                      type
                    }
                  }
                }
                locations {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: {
          systemId,
          accountId
        },
        fetchPolicy: 'no-cache'
      })
    },

    // ---

    searchBatchSetting (searchText) {
      this.batchSettingsPickerOptions.list = this.accountBatchSettings.filter(batchSetting => {
        return batchSetting.description.toLowerCase().includes(searchText.toLowerCase()) ||
          batchSetting.id === Number(searchText)
      }).sort((a, b) => {
        if (b.id === Number(searchText)) {
          return 1
        } else {
          return a.description.toLowerCase().indexOf(searchText.toLowerCase()) -
            b.description.toLowerCase().indexOf(searchText.toLowerCase())
        }
      }).map(batchSetting => {
        return {
          value: batchSetting.id,
          label: batchSetting.description,
          altLabel: `${batchSetting.description} <span class="id-tag float-right">#${batchSetting.id}</span>`,
          layout: {
            id: batchSetting.layout.id,
            name: batchSetting.layout.description
          }
        }
      })
    },

    batchSettingSelected (item) {
      this.orderItem.vehicle.id = item.layout.id
      this.orderItem.vehicle.name = item.layout.name
      this.orderItem.batchSettings.id = item.value
      this.orderItem.batchSettings.name = item.label

      this.$refs.quantity.focus()
    },

    locationsConfirm () {
      this.$refs.locationsModal.close(this.selectedLocations)

      this.selectedLocations = null
    },

    async addItem () {
      if (this.orderItem.vehicle.id === null) {
        return
      }

      if (this.orderItem.quantity === 0 || this.orderItem.quantity === '') {
        return
      }

      const locations = await this.$refs.locationsModal.open() ?? [{
        id: 0,
        name: '(None)'
      }]

      const existingOrderItem = this.order.items.find(item => {
        return item.batchSettings.id === this.orderItem.batchSettings.id &&
          item.locations.map(i => i.id).join(',') === locations.map(i => i.id).join(',')
      })

      if (existingOrderItem) {
        existingOrderItem.quantity += this.orderItem.quantity
      } else {
        this.order.items.push({
          id: Math.random(),
          batchSettings: {
            id: this.orderItem.batchSettings.id,
            name: this.orderItem.batchSettings.name
          },
          vehicle: {
            id: this.orderItem.vehicle.id,
            name: this.orderItem.vehicle.name
          },
          locations,
          quantity: this.orderItem.quantity
        })
      }

      this.orderItem.vehicle.id = null
      this.orderItem.vehicle.name = ''
      this.orderItem.batchSettings.id = null
      this.orderItem.batchSettings.name = ''
      this.orderItem.quantity = ''

      this.$refs.batchSetting.reset()
    },

    resetItemAdding () {
      this.orderItem.quantity = ''
      this.$refs.batchSetting.reset()
    },

    removeOrderItem (id) {
      this.order.items = this.order.items.filter(i => i.id !== id)

      this.resetItemAdding()
    },

    removeLastOrderItem () {
      this.order.items = this.order.items.slice(0, this.order.items.length - 1)

      this.resetItemAdding()
    },

    generateDevicesMockup () {
      const self = this

      this.devicesMockup = (function * () {
        const total = self.order.items.reduce((a, { quantity }) => a + quantity, 0)

        let totalCount = 0

        for (const { vehicle, batchSettings, quantity, locations } of self.order.items) {
          const totalOfType = quantity

          let totalOfTypeCount = 0

          for (let i = 0; i < quantity; i++) {
            const { sensors } = self.accountBatchSettings.find(({ id }) => id === batchSettings.id).layout

            const ret = {
              totalCount: ++totalCount,
              totalOfTypeCount: ++totalOfTypeCount,

              total,
              totalOfType,

              vehicle,
              batchSettings,
              locations,

              sensors: sensors.map(({ index, type, name }) => ({ index, type, name }))
            }

            yield ret
          }
        }
      })()

      this.devicesMockupCurrentItem = this.devicesMockup.next()
    },

    resetWizardFocus () {
      this.$nextTick().then(() => {
        const firstIinputElement = this.$refs.wizard.currentPage.$el.querySelector('input, select')

        if (firstIinputElement) {
          firstIinputElement.focus()
        }
      })
    },

    // ---

    goToSensors () {
      const deviceId = this.devicesMockupCurrentItem.value.id ?? ''

      if (!(/^[0-9]{15}$/.test(deviceId))) {
        this.validatePages.pageDevicesInvalidImei = 'Invalid IMEI number'
        this.playAudio(AUDIO_ERROR)
        return false
      }

      const existingDevice = this.order.devices
        .find(i => i.id === deviceId)

      if (existingDevice) {
        this.validatePages.pageDevicesInvalidImei = 'Device already been scanned'
        this.playAudio(AUDIO_ERROR)
        return false
      }

      this.validatePages.pageDevicesInvalidImei = null

      const $firstSensor = this.$refs.sensor?.[0]

      if ($firstSensor) {
        $firstSensor.focus()
      } else {
        this.finishDevice()
      }
    },

    goToSensor (index) {
      const $nextSensor = this.$refs.sensor[index]

      const sensorId = this.devicesMockupCurrentItem.value.sensors[index - 1].id?.trim().toUpperCase() ?? ''

      if (sensorId.length > 0) {
        if (!/^[A-F0-9]{6}$/.test(sensorId)) {
          this.toast('Invalid sensor ID')
          return false
        }

        let existingSensor = this.order.devices.map(i => i.sensors)
          .flat()
          .find(i => i.id === sensorId)

        if (!existingSensor) {
          existingSensor = this.devicesMockupCurrentItem.value.sensors
            .filter(i => i.id === sensorId)

          if (existingSensor.length > 1) {
            this.toast('Sensor already been scanned itself')
            return false
          }
        } else {
          this.toast('Sensor already been scanned')
          return false
        }
      }

      if (this.$refs.sensor.length === (index + 1)) {
        this.playAudio(AUDIO_NOTIFY)
      }

      if ($nextSensor) {
        $nextSensor.focus()
      } else {
        this.finishDevice()
      }
    },

    processSensorId (sensor) {
      let sensorId = sensor.id ?? ''

      if (sensorId.charAt(6) === '-') {
        const sensorParts = sensorId.split('-')

        sensorId = `${sensorParts[0]}_${sensorParts[1]}_${sensorParts[2]}-${sensorParts[3]}_${sensorParts[4]}`
      }

      if (sensorId.split('_').length > 4) {
        const sensorParts = sensorId.split('_')

        sensorId = `${sensorParts[0]}_${sensorParts[1]}_${sensorParts[2]}-${sensorParts[3]}_${sensorParts[4]}`
      }

      const sensorParts = sensorId.split('_')

      sensor.id = sensorParts[0].trim()

      if (sensorParts.length > 1) {
        sensor.meta = {
          manufacturingDateCode: sensorParts[1] ?? null,
          manufacturingPartNumber: sensorParts[2] ?? null,
          manufacturingQualityControlCode: sensorParts[3] ?? null
        }
      }
    },

    finishDevice () {
      const next = this.devicesMockup.next()

      this.order.devices.push(this.devicesMockupCurrentItem.value)

      if (next.done) {
        this.createOrder()
        this.playAudio(AUDIO_FINISHED)
      } else {
        this.devicesMockupCurrentItem = next

        this.$refs.imei.focus()
      }
    },

    scrollToSensor (index) {
      const $container = this.$refs.devicesPage.$el.parentElement
      const $nextSensor = this.$refs.sensor[index]

      $container.scroll({
        top: $nextSensor.offsetTop - ($container.offsetHeight / 2),
        behavior: 'smooth'
      })
    },

    scrollToTop () {
      const $container = this.$refs.devicesPage.$el.parentElement

      $container.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },

    resetSensor (index, sensor) {
      if ((sensor.id ?? '') === '') {
        if ((index - 1) >= 0) {
          this.$refs.sensor[index - 1].focus()
        } else {
          this.$refs.imei.focus()
        }
      } else {
        sensor.id = ''

        sensor.meta = undefined
      }
    },

    resetImei () {
      this.devicesMockupCurrentItem.value.id = ''
      this.validatePages.pageDevicesInvalidImei = null
    },

    createOrder () {
      this.wizardPageLoading = true

      this.$apollo.mutate({
        mutation: gql`
          mutation ($data: FastOrderInput!) {
            fast {
              order: createOrder(data: $data) {
                id: _id
              }
            }
          }
        `,
        variables: {
          data: this.orderData
        }
      }).then(response => {
        this.order.id = response.data.fast.order.id
      }).catch(error => {
        this.orderError = error

        setTimeout(() => {
          this.playAudio(AUDIO_ERROR)
        }, 2500)
      }).finally(() => {
        setTimeout(() => {
          this.next()
          this.wizardPageLoading = false
        }, 2000)
      })
    },

    // ---

    validateOrderPage () {
      if (this.order.number === '') {
        this.validatePages.pageOrderInvalidOrderNumber = 'Invalid order number'
        this.playAudio(AUDIO_ERROR)
        return false
      } else {
        this.validatePages.pageOrderInvalidOrderNumber = null
      }

      return true
    },

    validateClientPage () {
      if (this.order.client === null) {
        this.toast('No client selected')
        return false
      }

      return true
    },

    validateOrderItemsPage () {
      if (this.order.items.length === 0) {
        this.toast('At least one item is required')
        return false
      }

      return true
    },

    validateDevicesPage () {
      if (this.order.devices.length !== this.orderItemsSummary.total) {
        this.toast('Missing devices')
        return false
      }

      return true
    },

    // ---

    next () {
      this.$refs.wizard.next()

      this.resetWizardFocus()
    },

    resetWizard () {
      this.order.id = ''
      this.order.number = ''
      this.order.client = null
      this.order.items = []
      this.order.devices = []

      this.accountBatchSettings = null

      this.devicesMockup = null
      this.devicesMockupCurrentItem = null

      this.orderError = null

      this.$refs.wizard.restart()

      this.resetWizardFocus()
    },

    playAudio (audio) {
      audio.play()
    },

    toast (message, duration = 3000) {
      if (this.$refs.toast) {
        this.$refs.toast.show(message, duration)
        this.playAudio(AUDIO_ERROR)
      }
    }
  },

  computed: {
    orderItemsSummary () {
      return {
        items: this.order.items.length,
        total: this.order.items.reduce((a, { quantity }) => a + quantity, 0)
      }
    },

    deviceItemsProgress () {
      return {
        type: (100 / (this.devicesMockupCurrentItem?.value.totalOfType ?? 0)) * (this.devicesMockupCurrentItem?.value.totalOfTypeCount ?? 0) + '%',
        total: (100 / (this.devicesMockupCurrentItem?.value.total ?? 0)) * (this.devicesMockupCurrentItem?.value.totalCount ?? 0) + '%'
      }
    },

    orderData () {
      return {
        number: this.order.number,
        system: {
          id: this.order.client?.systemId,
          name: this.order.client?.systemName
        },
        client: {
          id: this.order.client?.value,
          name: this.order.client?.label
        },
        items: this.order.items.map(item => {
          return {
            vehicle: item.vehicle,
            batchSettings: item.batchSettings,
            locations: item.locations.filter(i => i.id !== 0).map(({ id, name }) => ({ id, name })),
            quantity: item.quantity,
            devices: this.order.devices.filter(({ batchSettings, locations }) => {
              const deviceLocationsString = locations.map(i => i.id).sort().join(',')
              const itemLocationsString = item.locations.map(i => i.id).sort().join(',')

              return (batchSettings.id === item.batchSettings.id) && (deviceLocationsString === itemLocationsString)
            }).map(device => {
              return {
                deviceId: device.id,
                sensors: device.sensors.filter(({ id }) => (id ?? '') !== '')
              }
            })
          }
        })
      }
    },

    parsedOrderError () {
      const params = this.orderError?.message.match(/{reason:(.+?)}.+?{orderId:(.+?)}/)

      return {
        reason: params[1],
        orderId: params[2]
      }
    }
  },

  data () {
    return {
      order: {
        id: '',
        number: '',
        client: null,
        items: [],
        devices: []
      },

      orderItem: {
        batchSettings: {
          id: null,
          name: ''
        },
        vehicle: {
          id: null,
          name: ''
        },
        quantity: ''
      },

      orderError: null,

      accountBatchSettings: null,
      accountLocations: null,

      devicesMockup: null,
      devicesMockupCurrentItem: null,

      clientPickerOptions: {
        list: [],
        loading: false
      },

      batchSettingsPickerOptions: {
        list: []
      },

      selectedLocations: null,

      validatePages: {
        pageOrderInvalidOrderNumber: null,
        pageDevicesInvalidImei: null
      },

      wizardPageLoading: false
    }
  },

  mounted () {
    this.resetWizardFocus()
  }
}
</script>

<style lang="less" scoped>
.progress {
  margin-bottom: 10px;

  .title {
    font-size: 10pt;
    opacity: 0.7;
  }

  .progress-bar {
    @height: 8px;

    background: fade(contrast(@main-color), 20%);
    height: @height;
    width: 100%;
    margin-top: 3px;
    overflow: hidden;
    border-radius: 10px;

    > div {
      height: @height;
      width: 0%;
      background: contrast(@main-color);
      transition: width 200ms;
    }
  }
}

.home {
  .wizard {
    position: fixed;
    top: 55px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-radius: 15px;
    color: contrast(@main-color);

    .locations-list {
      width: 500px;
    }

    p {
      margin-bottom: 30px;
    }

    .separator {
      color: fade(contrast(@main-color), 20%);
      margin: 0 3px;
      font-size: 15pt;
      vertical-align: middle;
    }

    .devices-page {
      /deep/ .title {
        margin-bottom: 0;
      }

      .devices-page-content {
        .header {
          position: sticky;
          top: 0;
          padding: 20px 0 150px 0;
          font-family: Roboto, sans-serif;
          background: linear-gradient(to bottom, tint(contrast(contrast(@main-color)), 6%) 0%, tint(contrast(contrast(@main-color)), 6%) 72%, transparent 100%);
          z-index: 1;

          .total-counter {
            font-family: Rajdhani, sans-serif;
            font-size: 40pt;
            position: absolute;
            top: 10px;
            right: 20px;
          }

          .title {
            text-transform: initial;
            font-size: 30pt;

            > div {
              display: inline-block;
              opacity: 0.8;
            }

            .counter {
              margin-right: 20px;
              font-family: Rajdhani, sans-serif;
              vertical-align: top;
            }

            .description {
              font-weight: bolder;

              span {
                font-size: 18pt;
                opacity: 0.5;
                font-weight: 100;
              }

              .locations {
                font-size: 14pt;
                font-weight: normal;
                margin-left: 5px;
              }
            }
          }
        }

        .inputs-container {
          margin: 30px auto 150px auto;
          width: 600px;

          /deep/ .imei-input input {
            background: fade(contrast(@main-color), 5%);
            font-size: 22pt;
            text-align: center;
            font-family: monospace;
          }

          .sensors-container {
            background: fade(contrast(@main-color), 3%);
            border-radius: 10px;
            overflow: hidden;
            margin-top: 10px;

            .sensor-item {
              display: flex;
              transition: background 300ms;

              > div {
                flex: 1;
              }

              .sensor-input {
                background: transparent;
                border: none;
                font-size: 16pt;
                width: 100%;
                outline: none;
                text-align: center;
                color: contrast(@main-color);
                padding: 10px;
                font-family: monospace;
                text-transform: uppercase;
                border-right: 1px solid fade(contrast(@main-color), 3%);
              }

              .sensor-name {
                border-right: 1px solid fade(contrast(@main-color), 10%);
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0.4;
              }

              .meta-info {
                display: flex;
                font-family: monospace;
                text-align: center;
                align-items: center;
                justify-content: center;
                opacity: 0.7;
                background: fade(contrast(@main-color), 0.5%);
                flex: 0 0 110px;
              }

              &:focus-within {
                background: fade(contrast(@main-color), 5%);

                .sensor-name {
                  opacity: 0.8;
                }
              }
            }
          }
        }
      }
    }

    .done-page {
      h1 {
        margin: 60px 0 0 40px;
        font-weight: bold;

        i {
          margin-right: 20px;
          color: @success-color;
        }
      }

      h2 {
        font-weight: 100;
        margin: 20px 0 0 100px;
      }

      .order-error {
        h1 {
          color: @danger-color;

          i {
            color: @danger-color;
          }
        }
      }
    }
  }

  .table {
    .header,
    .row {
      display: flex;
      align-items: center;

      > div {
        flex: 1;
      }

      .quantity {
        flex: 0 0 180px;
        margin-left: 1px;
        text-align: center;
      }

      .controls {
        flex: 0 0 0;
        margin-left: 5px;
      }
    }

    .header {
      border-bottom: 1px dotted fade(contrast(@main-color), 10%);

      > div {
        margin-bottom: 10px;
        opacity: 0.5;
      }
    }

    .row {
      /deep/ .vehicle-type input {
        border-radius: 8px 0 0 8px;
      }

      /deep/ .quantity input {
        border-radius: 0 8px 8px 0;
      }

      .location {
        padding-left: 40px;
      }

      .controls {
        button {
          width: 40px;
          height: 40px;
          border: none;
          background: transparent;
          color: @danger-color;
          font-size: 15pt;
          cursor: pointer;
          opacity: 0.5;
          transition: all 200ms;

          &:hover {
            opacity: 1;
          }

          &:active {
            opacity: 0.5;
          }
        }
      }

      &.input {
        margin-top: 10px;
      }

      &:not(.input, .controls) {
        padding: 0 10px;
      }

      &:nth-child(odd):not(.input) {
        background: fade(contrast(@main-color), 1%);
      }

      &:nth-child(even):not(.input) {
        background: fade(contrast(@main-color), 3%);
      }
    }
  }

  .selected-item {
    margin-top: 100px;
  }
}

.location-item {
  display: flex;

  > div {
    flex: 1;
  }

  .icon {
    display: flex;
    flex: 0 0 45px;
    align-items: center;
    justify-content: center;
    font-size: 14pt;
    margin-right: 10px;
    color: tint(@accent-color, 10%);
  }

  .name {
    display: inline-block;
    width: 300px;
  }
}
</style>
