<template>
  <div class="wizard-page" v-show="show">
    <h1 class="title">{{title}}</h1>

    <slot/>

    <portal :to="id + '-summary-panel'">
      <slot name="summary-panel"/>
    </portal>

    <portal :to="id + '-status-bar'">
      <slot name="status-bar"/>
    </portal>
  </div>
</template>

<script>
export default {
  name: 'WizardPage',

  props: {
    title: {
      type: String,
      required: true
    },

    validate: {
      type: Function,
      default () { return true },
      required: false
    },

    hideBack: {
      type: Boolean,
      default: false,
      required: false
    },

    hideNext: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    id () {
      return this.title.toLowerCase().split(' ').join('-').trim()
    }
  },

  data () {
    return {
      show: false
    }
  }
}
</script>

<style lang="less" scoped>
.wizard-page {
  padding: 25px;

  > .title {
    font-family: Rajdhani, sans-serif;
    text-transform: uppercase;
    margin: 0 0 20px;
    opacity: 0.8;
  }
}
</style>
