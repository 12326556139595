<template>
  <span class="copiable-text">
    <span ref="content">
      <slot/>
    </span>
    <button title="Copy to clipboard" @click="copy">
      <i v-if="copied" class="fal fa-check copied"></i>
      <i v-else class="fal fa-clone"></i>
    </button>
  </span>
</template>

<script>
export default {
  name: 'CopiableText',

  methods: {
    copy () {
      navigator.clipboard.writeText(this.$refs.content.textContent).then(() => {
        this.copied = true

        setTimeout(() => {
          this.copied = false
        }, 2000)
      })
    }
  },

  data () {
    return {
      copied: false
    }
  }
}
</script>

<style lang="less" scoped>
.copiable-text {
  button {
    background: transparent;
    border: none;
    color: contrast(@main-color);
    opacity: 0.8;
    cursor: pointer;
    margin-left: 5px;
    font-size: 0.8em;

    i.copied {
      color: @success-color;
    }

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 0.8;
    }
  }
}
</style>
