<template>
    <div>
      <transition name="fade">
        <div v-if="message !== ''" class="toast">
          <div class="icon">
            <i class="fas fa-exclamation-circle"></i>
          </div>
          <div class="message" v-html="message"></div>
        </div>
      </transition>
    </div>
</template>

<script>
export default {
  name: 'Toast',

  methods: {
    show (message, timeout = 3000) {
      this.message = message

      setTimeout(() => {
        this.message = ''
      }, timeout)
    }
  },

  data () {
    return {
      message: ''
    }
  }
}
</script>

<style lang="less" scoped>
.toast {
  @color: #9b0909;

  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: @color;
  border-radius: 5px;
  padding: 10px;
  color: contrast(@color);
  cursor: default;
  user-select: none;
  white-space: nowrap;

  > div {
    display: inline-block;
  }

  .icon {
    font-size: 18pt;
    vertical-align: middle;
    opacity: 0.5;
  }

  .message {
    padding: 0 10px;
    vertical-align: middle;
  }
}
</style>
