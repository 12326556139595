import Vue from 'vue'
import Vuex from 'vuex'
import { decode as decodeToken } from 'jsonwebtoken'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: {
      token: null,
      permissions: null,
      user: {
        user: '',
        employeeId: ''
      },
      sessionId: null
    }
  },
  mutations: {
    setAuth (state, payload) {
      state.auth = payload
    }
  },
  actions: {
    SET_AUTH ({ commit }, token) {
      const tokenData = decodeToken(token)

      localStorage.setItem('token', token)

      commit('setAuth', {
        token,
        permissions: tokenData.per,
        user: tokenData.usr,
        sessionId: tokenData.sid
      })
    },

    UNSET_AUTH ({ commit }) {
      localStorage.removeItem('token')

      commit('setAuth', {
        token: null,
        permissions: null,
        user: null,
        sessionId: null
      })
    }
  },
  modules: {
  }
})
