<template>
  <dialog class="modal" ref="modalEl">
    <div class="modal-title">
      <span>{{title}}</span>
      <div class="modal-close" @click="close"></div>
    </div>
    <div class="modal-content">
      <slot/>
    </div>
    <div class="modal-footer">
      <slot name="footer"/>
    </div>
  </dialog>
</template>

<script>
class DeferredPromise {
  constructor () {
    this.promise = new Promise(resolve => {
      this.resolve = resolve
    })

    this.promise.resolve = (payload) => {
      resetDeferredPromise()

      this.resolve(payload)
    }

    return this.promise
  }
}

let deferredPromise = null

function resetDeferredPromise () {
  deferredPromise = new DeferredPromise()
}

resetDeferredPromise()

export default {
  name: 'Modal',

  deferredPromise: null,

  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: String
  },

  methods: {
    open () {
      try {
        this.$refs.modalEl.showModal()
      } catch {}

      this.$emit('input', true)
      this.$emit('open')

      return deferredPromise
    },

    close (payload) {
      deferredPromise.resolve(payload)

      this.$refs.modalEl.close()

      this.$emit('input', false)
      this.$emit('closed')
    }
  },

  watch: {
    value (val) {
      if (val === true) {
        this.open()
      } else if (val === false) {
        this.close()
      }
    }
  },

  mounted () {
    this.$refs.modalEl.addEventListener('close', () => {
      this.close()
    })
  }
}
</script>

<style lang="less" scoped>
.modal {
  position: fixed;
  top: 100px;
  margin: 0 auto;
  border: none;
  border-radius: 20px;
  padding: 30px;
  background: tint(@main-color, 1%);
  color: contrast(@main-color);
  animation: modal-animation-in 300ms cubic-bezier(0.03, 0.15, 0.2, 1.24);

  &:not([open]) {
    animation: modal-animation-out 300ms cubic-bezier(0.03, 0.15, 0.2, 1.24);
  }

  .modal-title {
    position: relative;
    margin-bottom: 20px;
    user-select: none;

    span {
      font-family: Rajdhani, sans-serif;
      margin-right: 50px;
      font-size: 14pt;
      font-weight: 600;
      text-transform: uppercase;
    }

    .modal-close {
      @size: 12px;

      position: absolute;
      top: 5px;
      right: 0;
      font-size: 18pt;
      width: @size;
      height: @size;
      border-radius: @size / 2;
      background: fade(@danger-color, 60%);
      cursor: pointer;
      transition: all 300ms;

      &:hover {
        background: fade(@danger-color, 80%);
      }

      &:active {
        background: fade(@danger-color, 30%);
      }
    }
  }

  .modal-content {
    overflow: auto;
  }

  .modal-footer {
    margin-top: 15px;
    text-align: right;

    button {
      margin-left: 10px;
    }
  }
}

@keyframes modal-animation-in {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes modal-animation-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: sacle(0);
    opacity: 0;
  }
}
</style>
