<template>
  <div class="item-picker">
    <label>
      <div class="label">{{label}}</div>
      <div class="search-container">
        <input v-model="searchText" type="search" class="search" ref="searchInput"
          :class="{'result-open': resultList.length > 0}"
          :placeholder="$attrs.placeholder"
          @input="$emit('input', searchText)"
          @keyup="resetReadyToJump"
          @keyup.enter="search"
          @keyup.down="goToResultList"
          @keyup.esc="clearResultList"
        >
        <div v-if="loading" class="loading">
          <i class="fal fa-spinner-third fa-spin"></i>
        </div>
      </div>
    </label>

    <div class="result-list">
      <div v-for="item of resultList" class="result-item" tabindex="0" ref="resultItems"
        :key="item.id || item.value"
        :data-value="item.value"
        v-html="item.altLabel || item.label"
        @keyup.esc.prevent="escapeFromSelection"
        @keyup.enter="itemSelected(item)"
        @keyup.up="previousItemFocus($event)"
        @keyup.down="nextItemFocus($event)"
        @click="itemSelected(item)"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemPicker',

  props: {
    label: {
      type: String,
      default: null
    },
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  methods: {
    search () {
      if (this.resultList.length === 0 && this.searchText.length === 0) {
        this.skipCmd++

        if (this.skipCmd >= 2) {
          this.$emit('skip')

          return
        } else {
          const timer = setTimeout(() => {
            this.skipCmd = 0

            clearTimeout(timer)
          }, 500)
        }
      }

      if (this.resultList.length > 0 && this.readyToJump) {
        this.goToResultList()

        return
      }

      if (this.searchText.length > 0) {
        this.resultList = []

        this.$emit('search', this.searchText)
      }
    },

    clearResultList () {
      this.resultList = []
      this.readyToJump = false

      this.$emit('list-cleared')
    },

    escapeFromSelection () {
      this.$refs.searchInput.focus()
    },

    resetReadyToJump (e) {
      if (e.which !== 13) {
        this.readyToJump = false
      }
    },

    itemSelected (item) {
      this.searchText = item.label
      this.resultList = []

      this.escapeFromSelection()

      this.$emit('select', item)
    },

    goToResultList () {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.resultItems?.[0]?.focus()
    },

    nextItemFocus (e) {
      const targetElement = e.target.nextElementSibling

      if (targetElement) {
        targetElement.focus()
      } else {
        this.$refs.resultItems[0].focus()
      }
    },

    previousItemFocus (e) {
      const targetElement = e.target.previousElementSibling

      if (targetElement) {
        targetElement.focus()
      } else {
        this.$refs.searchInput.focus()
      }
    },

    reset () {
      this.searchText = ''
      this.resultList = []

      this.$nextTick().then(() => {
        this.$refs.searchInput.focus()
      })
    }
  },

  watch: {
    data (value) {
      this.readyToJump = true
      this.resultList = value
    }
  },

  data () {
    return {
      searchText: '',
      resultList: [],
      selectedItem: null,
      readyToJump: false,
      skipCmd: 0
    }
  }
}
</script>

<style lang="less" scoped>
.item-picker {
  position: relative;

  .label {
    font-family: Rajdhani, sans-serif;
  }

  .search-container {
    position: relative;

    .search {
      border: 1px solid transparent;
      border-radius: 8px;
      background: fade(contrast(@main-color), 10%);
      font-size: 15pt;
      padding: 10px;
      color: contrast(@main-color);
      outline: none;
      font-family: inherit;
      width: 100%;

      &.result-open {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }

    .loading {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 15pt;

      i {
        animation-duration: 500ms;
      }
    }
  }

  .result-list {
    background: shade(contrast(@main-color), 80%);
    margin-top: 2px;
    position: absolute;
    width: 100%;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    z-index: 1;

    .result-item {
      border: 1px solid transparent;
      padding: 10px 15px;
      color: fade(contrast(@main-color), 50%);
      outline: none;
      transition: all 100ms;
      cursor: pointer;

      &:hover,
      &:focus-visible {
        background: fade(contrast(@main-color), 2%);
      }

      &.selected {
        background: tint(@main-color, 20%);
        color: fade(contrast(@main-color), 80%);
      }

      &:focus-visible {
        border-color: fade(contrast(@main-color), 10%);
      }
    }
  }
}
</style>
