<template>
  <div id="app">
    <div v-if="status === 'ok'">
      <header-bar/>
      <router-view/>
    </div>

    <full-screen-loading v-if="status === 'loading'"/>
    <full-screen-locked-app v-if="status === 'access-denied'"/>
  </div>
</template>

<script>
import '@/assets/animations.css'

import gql from 'graphql-tag'
import FullScreenLoading from '@/components/FullScreenLoading.vue'
import FullScreenLockedApp from '@/components/FullScreenLockedApp.vue'
import HeaderBar from '@/components/HeaderBar.vue'

export default {
  name: 'App',

  components: {
    FullScreenLoading,
    FullScreenLockedApp,
    HeaderBar
  },

  methods: {
    setReady (status = 'ok') {
      setTimeout(() => {
        this.status = status
      }, 3000)
    },

    redirectToLogin () {
      const url = new URL(process.env.VUE_APP_AUTH_URL)

      url.searchParams.append('app', 'fast')
      url.searchParams.append('return_uri', this.$route.path)

      location.replace(url)
    }
  },

  data () {
    return {
      status: 'loading'
    }
  },

  mounted () {
    const authToken = this.$route.query.token

    if (authToken) {
      localStorage.setItem('token', authToken)

      this.$router.replace({
        query: {
          token: undefined
        }
      })
    }

    const token = localStorage.getItem('token')

    if (token) {
      this.$apollo.mutate({
        mutation: gql`
          mutation {
            auth {
              token: renewToken {
                hash
              }
            }
          }
        `
      }).then(response => {
        this.$store.dispatch('SET_AUTH', response.data.auth.token.hash)
      }).catch(() => {
        this.$store.dispatch('UNSET_AUTH')

        this.$nextTick().then(() => {
          this.redirectToLogin()
        })
      }).finally(() => {
        if (this.$checkPermission('fast.operator.operator')) {
          this.setReady()
        } else {
          this.setReady('access-denied')
        }
      })
    } else {
      this.redirectToLogin()
    }
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: Roboto, sans-serif;
  width: 100%;
  height: 100%;
  background: @main-color;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: none;
}
::-webkit-scrollbar-thumb {
  background: fade(contrast(@main-color), 5%);
  border-radius: 0 !important;
}
::-webkit-scrollbar-thumb:hover {
  background: fade(contrast(@main-color), 20%);
}

a {
  text-decoration: none;
  color: @accent-color;

  &:hover {
    text-decoration: underline;
  }
}

.btn {
  border: 2px solid transparent;
  padding: 8px 20px;
  font-family: Roboto, sans-serif;
  font-size: 12pt;
  background-color: lighten(@main-color, 10%);
  color: contrast(@main-color);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 200ms;
  user-select: none;

  &:focus {
    border-color: fade(@accent-color, 50%);
  }

  &:hover {
    background-color: lighten(@main-color, 15%);
  }

  &:active {
    background-color: darken(@main-color, 3%);
  }

  &:disabled {
    opacity: 0.3;
    background-color: tint(@main-color, 5%);
    cursor: default;
  }

  &.primary {
    background-color: @accent-color;
    color: contrast(@accent-color);

    &:hover {
      background-color: lighten(@accent-color, 15%);
    }

    &:active {
      background-color: darken(@accent-color, 3%);
    }

    &:disabled {
      opacity: 0.3;
      background-color: tint(@accent-color, 5%);
      cursor: default;
    }
  }
}

.id-tag {
  font-size: 0.8em;
  background: fade(contrast(@main-color), 20%);
  color: contrast(@main-color);
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 10px;
  vertical-align: middle;

  &.float-right {
    float: right;
  }

  &.order {
    display: inline-block;
    min-width: 45px;
    text-align: center;
  }
}

.system-tag {
  float: right;
  font-size: 0.8em;
  background: cadetblue;
  color: contrast(cadetblue);
  padding: 2px 5px;
  border-radius: 5px;

  &.default {
    background: #940000;
  }

  &.goodyear {
    background: #0000b7;
  }
}
</style>
